import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "animate.css"

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);


createApp(App).use(router).mount('#app')
