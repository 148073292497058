<template>
  <router-view/>
</template>

<style lang="scss">
@import './styles/public.scss';
@font-face {
  font-family: 'iconfont';  /* Project id 3275247 */
  src: url('//at.alicdn.com/t/font_3275247_g7vkxz67ldl.woff2?t=1648566074395') format('woff2'),
       url('//at.alicdn.com/t/font_3275247_g7vkxz67ldl.woff?t=1648566074395') format('woff'),
       url('//at.alicdn.com/t/font_3275247_g7vkxz67ldl.ttf?t=1648566074395') format('truetype');
}
.iconfont {
  font-family: "iconfont" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
